<template>
    <div class="container">
        <div class="section">
            <div class="columns">
                <div class="column is-4 is-offset-4">
                    <h1 class="title">Log In</h1>


                     <form @submit.prevent="submitForm">
                        <div class="field">
                            <label>Email</label>
                            <div class="control">
                                <input type="email" name="email" class="input" v-model="username">
                            </div>
                        </div>

                        <div class="field">
                            <label>Password</label>
                            <div class="control">
                                <input type="password" name="password" class="input" v-model="mainpassword">
                            </div>
                        </div>


                        <div class="notification is-danger" v-if="errors.length">
                            <ul>
                                <li v-for="error in errors" v-bind:key="error">{{ error }} </li>
                            </ul>
                        </div>


                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Submit</button>
                            </div>
                        </div>


                    </form>
                    <div>
                        <router-link to="/Forget-password" style="font-size:x-small">Forget password?</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    export default{
        name:'LogIn',
        data(){
            return {
                username:'',
                mainpassword: '',
                errors: []
            }
        },
        mounted(){
            this.activateUser()
        },
        methods:{
            activateUser(){

                if(this.$route.params.uidlogin != '' && this.$route.params.tokenlogin != '' ){
                    const formData = {
                        uid:  this.$route.params.uidlogin,
                        token:  this.$route.params.tokenlogin,
                    }                    
                    axios.post('api/v1/users/activation/',formData)
                    .then(response => {
                        const token = response.data.auth_token
                        this.$store.commit('setToken', token)

                        axios.defaults.headers.common['Authorization'] = "Token " + token
                        localStorage.setItem('token', token)
                    })
                    .catch(error => {
                        if(error.response){
                            for (const property in error.response.data){
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message){
                            this.errors.push('Something went wrong, please try again!')
                        }
                    })
                }
            },
            


            async submitForm(){
                this.$store.commit('setIsLoading', true)
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')

                const formData = {
                    email:  this.username,
                    password:  this.mainpassword,
                } 
                await axios
                    .post('api/v1/token/login/', formData)
                    
                    .then(response => {
                        if(response.status == 200 && response.data.auth_token){
                            const token = response.data.auth_token
                            this.$store.commit('setToken', token)

                            axios.defaults.headers.common['Authorization'] = "Token " + token
                            localStorage.setItem('token', token)
                            // this.$router.push('/dashboard/my-account')
                            this.$router.push('/my-account')
                        }
                    })
                    .catch(error => {
                        if(error.response){
                            for (const property in error.response.data){
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            console.log(error.response.data)
                        } else if (error.message){
                            this.errors.push('Something went wrong, please try again!')
                            console.log(error.message)
                        }
                    })
                this.$store.commit('setIsLoading', false) 
                       
            }
        }        
    }
</script>