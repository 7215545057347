<template>
    <div style=" overflow: hidden; background-color: white;">
        <div class="row">
			<div class="col-1">

            </div>
            <div class="col-6">
                <div class="row">
                    <form class="ms-2">
                        <div class="form-group row">
                            <div class="col-4">
                                <label for="discipline" class="col-sm-2 col-form-label fw-bold">Discipline</label>
                                <div class="col-sm-12">
                                    <select class="form-select form-control" id="discipline" @change="retrievePapers($event)" v-model="selectedDiscipline">
                                        <option v-for="item in discplines" :value="item" :key="item">{{item}}</option>
                                    </select>	
                                </div>
                            </div>
                            <div class="col-3">
                                <label for="date" class="col-sm-2 col-form-label fw-bold">Date</label>
                                <div class="col-sm-12">
                                    <input type="date"  class="datepicker" @change="retrievePapers()" v-model="selectionDate"/>
                                </div>
                            </div>
                            <div class="col-4 ms-1">
                                <label for="posted" class="col-form-label fw-bold">Papers</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="postedSwitch" v-model="postedPaper" @change="retrievePapers()"/>
                                    <label class="form-check-label" for="postedSwitch">Include posted</label>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row mt-4">
                        <TableView :papersIn="papers"/>
                    </div>



                </div>                
            </div>
            <div class="col-4 mt-5 pubmed-col">
                <div class="row mt-2 ms-2">            
                    <div class="col-2 mt-2 p-0 m-0">
                        PubMed ID:
                    </div>
                    <div class="col-3">
                        <input class="form-control" type="text" placeholder="PubMed ID" v-model="PubMedIdTxt">
                    </div>
                    <div class="col-1 mt-1 ms-0 ps-0">
                        <button type="button" class="btn btn-secondary btn-sm" @click="retrievePubMed">Get</button>
                    </div>                      
                    <div class="col-4 ms-2">
                        <select class="form-select form-control" id="twitterControl" v-model="selectedTwitterID" >
                            <option v-for="item in twitterids" :value="item" :key="item">{{item}}</option>
                        </select>	
                    </div> 
                    <div class="col-1 mt-1 ms-0 ps-0">
                        <button type="button" class="btn btn-warning btn-sm" @click="post_twitter_pubmed">Post</button>
                    </div>                              
                </div>
                <div class="row pubmed-result">
                    {{pubmedRecord}}
                </div>
                 
                <!-- <div class="card-header bg-light is-pulled-left p-2 m-0" style="position: absolute; right: 0; z-index: 10; opacity: 0.75;">
                    <transition name="fade">
                        <div v-if="elementVisible" style="background-color: aquamarine;">
                            <div class=" p-2"> 
                                The changes are applied successfully!
                            </div>
                        </div>
                    </transition>                           
                </div>   -->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { toast } from 'bulma-toast'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"
    // import Datepicker from '@vuepic/vue-datepicker';
    //import '@vuepic/vue-datepicker/dist/main.css';
    // import { mapState } from 'vuex'


    import TableView from "@/views/dashboard/TableView.vue"
    export default{
        name:'MyAccount',
        components: {
            TableView 
        },
        data() {
            return {
                todayDate: new Date().toLocaleDateString('en-CA'),
                discplines:[],
                twitterids:[],
                papers:[],
                postedPaper:false,
                selectedDiscipline:'',
                selectedTwitterID:'',
                selectionDate:new Date().toLocaleDateString('en-CA'),
                PubMedIdTxt: '',
                pubmedRecord: '',
                elementVisible: false,
            }
        },
        mounted() {
            this.populateDisciplines();
            this.retrieveTwitterIDs();
        },
        computed(){
          
        },
        methods: {
            async populateDisciplines(){
                let _this = this;
                await axios.get('data/discipline/').then(response => {
                    _this.discplines = response['data']['data'];
                    // console.log(_this.discplines[0]);
                    // _this.selectedDiscipline = _this.discplines[0];
                    // _this.retrievePapers();
                })
            },
            async retrievePapers(){
                let discipline = this.selectedDiscipline;
                let posted = this.postedPaper
                let date = this.selectionDate
                let _this = this;
                let data ={
                    'discipline': discipline,
                    'date': date,
                    'posted': posted
                }
                await axios.get('data/table/', {params:{data:data}}).then(response => {
                    _this.papers = response['data']['data'];
                })
            },
            async retrievePubMed(){
                if(this.PubMedIdTxt !== ''){
                    console.log(this.PubMedIdTxt);
                    let _this = this;
                    let data ={
                    'pid': _this.PubMedIdTxt,
                    }
                    await axios.get('data/pubmed/', {params:{data:data}}).then(response => {
                        let dta = response['data']['data'];
                        if (dta !== 'no doi' && dta !== 'no paper'){
                            _this.pubmedRecord =  dta;
                        }else{
                            if (dta === 'no doi'){
                                _this.pubmedRecord =  "No DOI found for the provided PubMed ID";
                            }else if(dta === 'no paper'){
                                _this.pubmedRecord =  "No Paper found for the provided PubMed ID";
                            }
                        }
                    })
                }
            },
            async retrieveTwitterIDs(){
                let _this = this;          
                await axios.get('data/twitterids/').then(response => {
                    _this.twitterids = response['data']['data'];
                    // console.log(_this.discplines[0]);
                    // _this.selectedDiscipline = _this.discplines[0];
                    // _this.retrievePapers();
                })
            },
            // handleChange(e){
            //     console.log(e.target.value)
            //     console.log(this.selectedTwitterID)
            // },
            async post_twitter_pubmed(){
                // let _this = this;
                if(this.selectedTwitterID ===''){
                    console.log(this.selectedTwitterID);
                    toast({
                                message: 'Please select the corresponding Twitter account!',
                                type: 'is-danger',
                                position: 'bottom-right',
                                closeOnClick: true,
                                pauseOnHover: true,
                                opacity: 0.8,
                                duration: 5000,
                                })
                }else{
                        let data ={
                        'pid': this.PubMedIdTxt,
                        'twitter_account': this.selectedTwitterID
                    }      
                    console.log(data)
                    await axios.get('data/totwitter/', {params:{data:data}}).then(response=>{
                        let dta = response['data']['data'];

                        if(dta === 'no doi'){
                            
                            toast({
                                message: 'We could not find the DOI for this PubMed ID, the paper is not posted!',
                                type: 'is-danger',
                                position: 'bottom-right',
                                closeOnClick: true,
                                pauseOnHover: true,
                                opacity: 0.8,
                                duration: 5000,
                                })
                        }else if(dta === 'posted'){
                            toast({
                                message: 'The paper was just posted to the Twitter account!',
                                type: 'is-primary',
                                position: 'bottom-right',
                                closeOnClick: true,
                                pauseOnHover: true,
                                opacity: 0.8,
                                duration: 5000,
                                })
                        }else if(dta === 'beenPosted'){
                            
                            toast({
                                message: 'The PubMed Id provided has been previously submitted in this twitter account!',
                                type: 'is-warning',
                                position: 'bottom-right',
                                closeOnClick: true,
                                pauseOnHover: true,
                                opacity: 0.8,
                                duration: 5000,
                                })
                        }else{
                            console.log(dta)
                            toast({
                                message: 'We found the DOI, but an error occurred while submitting the post.',
                                type: 'is-warning',
                                position: 'bottom-right',
                                closeOnClick: true,
                                pauseOnHover: true,
                                opacity: 0.8,
                                duration: 5000,
                                })
                        }



                    })
                }

            }
        }
    }
</script>

<style>
.datepicker {
	margin-top: 1px;
	border: solid 1px rgb(194, 194, 194) !important;
	border-radius: 7px !important;
	height: 38px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
	font-size: 0.9rem;
	background-color: rgb(252, 252, 252);
	color: #000;
	border-radius: 0;
	display: inline-block;
	position: relative;
	line-height: 30px;
    width: 100%;
}
.pubmed-col{
    border:1px solid rgb(200,200,200);
    border-radius: 5%; 
}
.pubmed-result{
    height:200px; 
    width:100%;
    margin-top: 40px;
    padding: 10px;
    padding-top: 40px;
}
</style>
