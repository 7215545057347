<template>
    <div class="container">
        <div class="section">
            <div class="columns">
                <div class="column is-4 is-offset-4">
                    <h1 class="title">Forget Password!</h1>

                    <form @submit.prevent="sendResetPasswordEmail" autocomplete="off">
                        <div class="field">
                            <label>Enter your email:</label>
                            <div class="control">
                                <input type="email" name="email" class="input" v-model="username">
                            </div>
                        </div>

                        <div class="notification is-danger" v-if="errors.length">
                            <ul>
                                <li v-for="error in errors" v-bind:key="error">{{ error }} </li>
                            </ul>
                        </div>



                        <div class="field">
                            <div class="control">
                                <button class="button is-success">{{submitLabel}}</button>
                                <div v-if="emailSent">{{message}}</div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default{
        name:'ForgetPassword',
        data(){
            return {
                username:'',
                errors: [],
                emailSent: false,
                submitLabel: 'Submit',
                message: 'An email containing a password reset link was sent to your email; click on the link to be directed to the reset password page!'
            }
        },
        methods:{
            async sendResetPasswordEmail(){
                if(this.emailSent){
                    this.message = 'An email containing a password reset link was resent to your email; click on the link to be directed to the reset password page!'
                }else{
                    this.message = 'An email containing a password reset link was sent to your email; click on the link to be directed to the reset password page!'
                }
                this.emailSent = true
                this.submitLabel = 'Resend'



                var formData = {
                        email: this.username
                    }
                console.log(formData)
                await axios
                    .post("/api/v1/users/reset_password/", formData)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
		}
	}
</script>