import { createApp } from 'vue'
// import { createStore } from 'vuex'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Bulma from 'bulma'
// import { library } from "@fortawesome/fontawesome-svg-core"
// import FontAwesomeIcon from "@fortawesome/vue-fontawesome";
// import { faSquareMinus, faSquarePlus, faPlus, faPen, faCheck, faArrowRight, faChartLine, faCircleXmark, faXmark, faArrowRotateLeft, faSpellCheck, faRightFromBracket, faCircleDown, faKey, faK } from "@fortawesome/free-solid-svg-icons"
// import Notifications from '@kyvg/vue3-notification'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// library.add(faSquareMinus);
// library.add(faSquarePlus);
// library.add(faXmark)
// library.add(faPlus)
// library.add(faPen)
// library.add(faCheck)
// library.add(faArrowRight)
// library.add(faChartLine)
// library.add(faCircleXmark)
// library.add(faArrowRotateLeft)
// library.add(faSpellCheck)
// library.add(faRightFromBracket)
// library.add(faCircleDown)
// library.add(faKey)


//axios.defaults.baseURL = 'http://127.0.0.1:8000'

const app = createApp(App)
app.use(store)
app.use(router, axios, Bulma)
// app.use(Notifications)
// app.component("font-awesome-icon", FontAwesomeIcon)

app.mount('#app')