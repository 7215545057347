<template>
    <div class="container">
        <div class="section">
            <div class="columns">
                <div class="column is-4 is-offset-4">
                    <h1 class="title">Change Password!</h1>

                    <form @submit.prevent="changePassword" autocomplete="off">
                        <div class="field">
                            <label>Email</label>
                            <div class="control">
                                <input type="email" name="email" class="input" v-model="username">
                            </div>
                        </div>

                        <div class="field">
                            <label>Old Password</label>
                            <div class="control">
                                <input type="password" name="password1" class="input" v-model="password1">
                            </div>
                        </div>

                        <div class="field">
                            <label>New Password</label>
                            <div class="control">
                                <input type="password" name="newpassword1" class="input" v-model="newpassword1">
                            </div>
                        </div>

                        <div class="field">
                            <label>Repeat New Password</label>
                            <div class="control">
                                <input type="password" name="newpassword2" class="input" v-model="newpassword2">
                            </div>
                        </div>


                        <div class="notification is-danger" v-if="errors.length">
                            <ul>
                                <li v-for="error in errors" v-bind:key="error">{{ error }} </li>
                            </ul>
                        </div>



                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Submit</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default{
        name:'PasswordChange',
        data(){
            return {
                username:'',
                password1: '',
                newpassword1: '',
                newpassword2:'',
                errors: []
            }
        },
        methods:{
            async changePassword(){
                

                var formData = {
                    new_password:  this.newpassword1,
                    re_new_password:this.newpassword2,
                    current_password:this.password1,
                }
                await axios
                    .post("/api/v1/users/set_password/", formData)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    this.$router.push('/log-in')
            }            
		}
	}
</script>