import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'


import HomeView from '../views/HomeView.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import PasswordChange from '../views/PasswordChange.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import NewPassword from '../views/NewPassword.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  // history: createWebHistory(),
  history: routerHistory,
  // base: '/app',
  base: __dirname,  
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/log-in/:uidlogin?/:tokenlogin?',
      name: 'LogIn',
      component: LogIn
    },
    {
      // path: '/dashboard/my-account',
      path: '/my-account',
      name: 'MyAccount',
      component: MyAccount,
      meta: {
        requrieLogin: true
      }
    },
    {
      path: '/Password-change',
      name: 'PasswordChange',
      component: PasswordChange,
      meta: {
        requrieLogin: true
      }      
    },
    {
      path: '/Forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword,
    }, 
    {
      path: '/New-password/:uidstr/:tokenstr',
      name: 'NewPassword',
      component: NewPassword,
    },    
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import('../views/AboutView.vue')
    // }
  ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requrieLogin) && !store.state.isAuthenticated){
    next('/log-in')
  } else {
    next()
  }
})

export default router
