<template>
  <div class="container ">
    <section class="is-fullheight" style="margin-top:5%">
      <div class="hero-body has-text-centered">
        <div class="container">
          <div class="columns is-centered">
                  <img class="logo" src="@/components/figures/small_logo2.svg" width="400">
          </div>
          <h1 class="is-size-1 has-text-weight-bold">Scholarly Articles</h1>
        </div>
      </div>
    </section>
</div>
</template>

<style scoped>
@-webkit-keyframes logo {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes logo {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

.logo {
    width: 300px;
    height: 300px;
    -webkit-animation: logo 20s linear infinite;
    -moz-animation: logo 20s linear infinite;
}
</style>