<template>
    <div class="container">
        <div class="section">
            <div class="columns">
                <div class="column is-4 is-offset-4">
                    <h1 class="title">Reset Password!</h1>

                    <form @submit.prevent="submitNewPassword" autocomplete="off">
                        <div class="field">
                            <label>New Password</label>
                            <div class="control">
                                <input type="password" name="password1" class="input" v-model="newpassword1">
                            </div>
                        </div>

                        <div class="field">
                            <label>Repeat New Password</label>
                            <div class="control">
                                <input type="password" name="password2" class="input" v-model="newpassword2">
                            </div>
                        </div>


                        <div class="notification is-danger" v-if="errors.length">
                            <ul>
                                <li v-for="error in errors" v-bind:key="error">{{ error }} </li>
                            </ul>
                        </div>



                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Submit</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default{
        name:'NewPassword',
        data(){
            return {
                username:'',
                newpassword1: '',
                newpassword2: '',
                errors: []
            }
        },
        methods:{
            async submitNewPassword(){
                const formData = {
                    uid:  this.$route.params.uidstr,
                    token:this.$route.params.tokenstr,
                    new_password:this.newpassword1,
                    re_new_password: this.newpassword2
                }

                await axios
                    .post("/api/v1/users/reset_password_confirm/", formData)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    this.$router.push('/log-in')
            }

                            
		}
	}
</script>