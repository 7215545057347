<template>
    <div>
			<div>
				<table class="table table-striped paper-table ms-2">
					<thead class="table-warning">
						<tr>
							<th scope="col">Title</th>
							<th scope="col">Authors</th>
							<th scope="col">Journal</th>
							<th scope="col">Posted?</th>
							<th scope="col">Post</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in papersIn" :value="item[0]" :key="item[0]">
							<td scope="col">{{item[1]}}</td>
							<td scope="col">{{item[2]}}</td>
							<td scope="col">{{item[3]}}</td>
							<td v-if="item[4]">&#10004;</td>
							<td v-else>&#x2718;</td>
							<td scope="col"><a href="#"><img src="../../components/icons/twitter-icon.svg" className="twitter-logo" alt="T" :id="item[0]" @click="Twitt($event)"/></a></td>
						</tr>										
					</tbody>
				</table>
			</div>
    </div>
</template>

<script>
    import axios from "axios"
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"

    export default{
        name:'TableView',
        components: {

        },
		props: ['papersIn'],
        data() {
            return {
				papers_data: this.papersIn,
            }
        },
        mounted() {
           
        },
        computed(){

          
        },
        methods: {
			async Twitt(event){
				let myID = event.currentTarget.id;
				
				const formData = {
                    pid: myID,
                }
				await axios.post("twitttt/", formData).then(() =>{

				})
			}
        }
    }
</script>

<style>
.paper-table{
	font-size: 0.9rem;
}
th{
	text-align: center;
	vertical-align: middle;
	color: white;
}
td{
	text-align: center;
	vertical-align: middle;
}
.twitter-logo{
	width: 30px;
	cursor: pointer;
	filter: drop-shadow(0 0.10rem 0.10rem rgba(0, 0, 0, 1));
}
.twitter-logo:active{
    margin: 3px 0 0 0 ;
	filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 1));
}
</style>